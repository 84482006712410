import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import { Link } from "gatsby";

const FouroFourPage = () => {
    return (
      <Layout>
        <div className="index-container" > 
          <Helmet title={config.siteTitle} />
          <SEO />
          <h1>A terrible error happened 😬</h1>
          <p>This is very unfortunate.</p>
          <p>But things could really be so much worse.</p>  
          <p>Try clicking around or just going back <Link to= '/'>home</Link></p>  
        </div>
      </Layout>
    )
  }

  export default FouroFourPage;